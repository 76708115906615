// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposits-container-p7lKJ {
  height: 100%;
  overflow: hidden;
}

.deposits-table_container-PT61b {
  width: initial;
  padding: 0px 10px;
  margin: 4px 0px 0px 10px;
}

.deposits-tableHeader_cellContent-xl3Qt {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8)
}

.deposits-description-JEsJV {
  width: 100%;
  min-width: 280px;
  white-space: normal;
  padding: 8px 26px;
}
`, "",{"version":3,"sources":["webpack://./src/components/deposits/deposits.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".container {\n  height: 100%;\n  overflow: hidden;\n}\n\n.table_container {\n  width: initial;\n  padding: 0px 10px;\n  margin: 4px 0px 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8)\n}\n\n.description {\n  width: 100%;\n  min-width: 280px;\n  white-space: normal;\n  padding: 8px 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `deposits-container-p7lKJ`,
	"table_container": `deposits-table_container-PT61b`,
	"tableHeader_cellContent": `deposits-tableHeader_cellContent-xl3Qt`,
	"description": `deposits-description-JEsJV`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-card-container-q5cvB {
  width: 360px;
  height: 100%;
  overflow-y: scroll;
}

.customer-card-container-q5cvB::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.customer-card-container-q5cvB:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.customer-card-container-q5cvB::-webkit-scrollbar-button {
  height: 4px;
}

.customer-card-data-Mollj {
  padding: 20px 6px 4px 16px;
}

.customer-card-data-Mollj div:last-child {
  border: none;
}

.customer-card-title_container-vUzcE {
  padding-bottom: 12px;
}

.customer-card-title_title-IrKqW {
  font-size: 17px;
}

.customer-card-textItem_title-YwXsG {
  color: rgba(0, 0, 0, 0.8);
}

.customer-card-textItem_text-SL8pk {
  color:rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/components/customer-card/customer-card.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".container {\n  width: 360px;\n  height: 100%;\n  overflow-y: scroll;\n}\n\n.container::-webkit-scrollbar-thumb {\n  visibility: hidden;\n}\n\n.container:hover::-webkit-scrollbar-thumb {\n  visibility: visible;\n}\n\n.container::-webkit-scrollbar-button {\n  height: 4px;\n}\n\n.data {\n  padding: 20px 6px 4px 16px;\n}\n\n.data div:last-child {\n  border: none;\n}\n\n.title_container {\n  padding-bottom: 12px;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.textItem_title {\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.textItem_text {\n  color:rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `customer-card-container-q5cvB`,
	"data": `customer-card-data-Mollj`,
	"title_container": `customer-card-title_container-vUzcE`,
	"title_title": `customer-card-title_title-IrKqW`,
	"textItem_title": `customer-card-textItem_title-YwXsG`,
	"textItem_text": `customer-card-textItem_text-SL8pk`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-body-x0TTw {
  height: calc(100vh - 45px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/main/main.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":[".body {\n  height: calc(100vh - 45px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `main-body-x0TTw`
};
export default ___CSS_LOADER_EXPORT___;

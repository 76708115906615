// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-reports-container-FrpOK {
  height: 100%;
  overflow: hidden;
}

.sales-reports-table_container-H7FbJ {
  width: initial;
  padding: 0px 10px;
  margin: 4px 0px 0px 10px;
}

.sales-reports-tableHeader_cellContent-YIgHl {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8)
}
`, "",{"version":3,"sources":["webpack://./src/components/sales-reports/sales-reports.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf;AACF","sourcesContent":[".container {\n  height: 100%;\n  overflow: hidden;\n}\n\n.table_container {\n  width: initial;\n  padding: 0px 10px;\n  margin: 4px 0px 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sales-reports-container-FrpOK`,
	"table_container": `sales-reports-table_container-H7FbJ`,
	"tableHeader_cellContent": `sales-reports-tableHeader_cellContent-YIgHl`
};
export default ___CSS_LOADER_EXPORT___;

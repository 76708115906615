// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-brand_container-rX3B8 {
  justify-content: center;
  margin: 72px auto 0px;
}

.login-card-ucDha {
  width: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  padding: 28px 28px 24px;
  margin: 60px auto 0px;
}

.login-cardIcon-FkN4p {
  --size: 48px;
  margin-top: 18px;
}

.login-cardTitle_container-UpzLN {
  margin-top: 16px;
}

.login-form-T85Cr {
  width: 100%;
  min-height: 200px;
  margin-bottom: 32px;
}

.login-docNumberField_container-WIB9E {
  width: 100%;
  margin-top: 44px;
}

.login-passwordField_container-FanI5 {
  width: 100%;
  margin-top: 24px;
}

.login-showPassword-IJymI {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding: 0px 4px;
  margin-top: 28px;
}

.login-showPasswordTitle-JBpWw {
  height: 20px;
  line-height: 20px;
}

.login-checkbox-yUCZ8 {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0px;
  margin-right: 8px;
}

.login-button_base-InH0X {
  margin-top: 28px;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/login.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".brand_container {\n  justify-content: center;\n  margin: 72px auto 0px;\n}\n\n.card {\n  width: 356px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border: 1px solid #ECECEC;\n  border-radius: 8px;\n  padding: 28px 28px 24px;\n  margin: 60px auto 0px;\n}\n\n.cardIcon {\n  --size: 48px;\n  margin-top: 18px;\n}\n\n.cardTitle_container {\n  margin-top: 16px;\n}\n\n.form {\n  width: 100%;\n  min-height: 200px;\n  margin-bottom: 32px;\n}\n\n.docNumberField_container {\n  width: 100%;\n  margin-top: 44px;\n}\n\n.passwordField_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.showPassword {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  cursor: pointer;\n  padding: 0px 4px;\n  margin-top: 28px;\n}\n\n.showPasswordTitle {\n  height: 20px;\n  line-height: 20px;\n}\n\n.checkbox {\n  width: 15px;\n  height: 15px;\n  cursor: pointer;\n  margin: 0px;\n  margin-right: 8px;\n}\n\n.button_base {\n  margin-top: 28px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand_container": `login-brand_container-rX3B8`,
	"card": `login-card-ucDha`,
	"cardIcon": `login-cardIcon-FkN4p`,
	"cardTitle_container": `login-cardTitle_container-UpzLN`,
	"form": `login-form-T85Cr`,
	"docNumberField_container": `login-docNumberField_container-WIB9E`,
	"passwordField_container": `login-passwordField_container-FanI5`,
	"showPassword": `login-showPassword-IJymI`,
	"showPasswordTitle": `login-showPasswordTitle-JBpWw`,
	"checkbox": `login-checkbox-yUCZ8`,
	"button_base": `login-button_base-InH0X`
};
export default ___CSS_LOADER_EXPORT___;

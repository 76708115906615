// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-brand_container-DdugD {
  justify-content: center;
  margin: 72px auto 0px;
}

.reset-password-card-b2dG_ {
  width: 360px;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  padding: 28px 24px 24px;
  margin: 60px auto 0px;
}

.reset-password-cardIcon-rBkp0 {
  --size: 48px;
  margin-top: 18px;
}

.reset-password-cardTitle_container-z0Up1 {
  margin-top: 24px;
}

.reset-password-form-sYOYo {
  width: 100%;
  min-height: 200px;
  margin-bottom: 44px;
}

.reset-password-passwordField_container-V_JZa {
  width: 100%;
  margin-top: 24px;
}

.reset-password-passwordField_container-V_JZa:first-child {
  margin-top: 44px;
}

.reset-password-showPassword-jV86d {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding: 0px 4px;
  margin-top: 28px;
}

.reset-password-showPasswordTitle-N5Oyn {
  height: 20px;
  line-height: 20px;
}

.reset-password-checkbox-xzvPB {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0px;
  margin-right: 8px;
}

.reset-password-button_base-EN7hh {
  margin-top: auto;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/reset-password/reset-password.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".brand_container {\n  justify-content: center;\n  margin: 72px auto 0px;\n}\n\n.card {\n  width: 360px;\n  min-height: 480px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border: 1px solid #ECECEC;\n  border-radius: 8px;\n  padding: 28px 24px 24px;\n  margin: 60px auto 0px;\n}\n\n.cardIcon {\n  --size: 48px;\n  margin-top: 18px;\n}\n\n.cardTitle_container {\n  margin-top: 24px;\n}\n\n.form {\n  width: 100%;\n  min-height: 200px;\n  margin-bottom: 44px;\n}\n\n.passwordField_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.passwordField_container:first-child {\n  margin-top: 44px;\n}\n\n.showPassword {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  cursor: pointer;\n  padding: 0px 4px;\n  margin-top: 28px;\n}\n\n.showPasswordTitle {\n  height: 20px;\n  line-height: 20px;\n}\n\n.checkbox {\n  width: 15px;\n  height: 15px;\n  cursor: pointer;\n  margin: 0px;\n  margin-right: 8px;\n}\n\n.button_base {\n  margin-top: auto;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand_container": `reset-password-brand_container-DdugD`,
	"card": `reset-password-card-b2dG_`,
	"cardIcon": `reset-password-cardIcon-rBkp0`,
	"cardTitle_container": `reset-password-cardTitle_container-z0Up1`,
	"form": `reset-password-form-sYOYo`,
	"passwordField_container": `reset-password-passwordField_container-V_JZa`,
	"showPassword": `reset-password-showPassword-jV86d`,
	"showPasswordTitle": `reset-password-showPasswordTitle-N5Oyn`,
	"checkbox": `reset-password-checkbox-xzvPB`,
	"button_base": `reset-password-button_base-EN7hh`
};
export default ___CSS_LOADER_EXPORT___;

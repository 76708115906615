// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-container-Jkmj2 {
  overflow-x: auto;
}

.business-content-ih5Le {
  min-width: 932px;
  display: flex;
}

.business-toolbarTitle_container-bHjJN {
  width: 360px;
  flex-shrink: 0;
  margin-right: 24px;
}

.business-toolbarRight-Eo0aA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.business-customerCard_container-Ap1yT {
  width: 360px;
  margin-right: 40px;
  flex-shrink: 0;
}

.business-outlineCard-poAbG {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.business-tab_container-xcWPa {
  border-radius: 4px;
  padding-top: 12px;
  margin-bottom: 8px;
}

.business-tab_title-okVb5 {
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/business/business.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  overflow-x: auto;\n}\n\n.content {\n  min-width: 932px;\n  display: flex;\n}\n\n.toolbarTitle_container {\n  width: 360px;\n  flex-shrink: 0;\n  margin-right: 24px;\n}\n\n.toolbarRight {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-grow: 1;\n}\n\n.customerCard_container {\n  width: 360px;\n  margin-right: 40px;\n  flex-shrink: 0;\n}\n\n.outlineCard {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n.tab_container {\n  border-radius: 4px;\n  padding-top: 12px;\n  margin-bottom: 8px;\n}\n\n.tab_title {\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `business-container-Jkmj2`,
	"content": `business-content-ih5Le`,
	"toolbarTitle_container": `business-toolbarTitle_container-bHjJN`,
	"toolbarRight": `business-toolbarRight-Eo0aA`,
	"customerCard_container": `business-customerCard_container-Ap1yT`,
	"outlineCard": `business-outlineCard-poAbG`,
	"tab_container": `business-tab_container-xcWPa`,
	"tab_title": `business-tab_title-okVb5`
};
export default ___CSS_LOADER_EXPORT___;

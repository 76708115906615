// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions-container-kM7HX {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.transactions-filters-n_qpV {
  display: flex;
  align-items: center;
  padding: 8px 12px 0px;
}

.transactions-datetime_container-A2zj7 {
  width: 220px;
}

.transactions-datetime_container-A2zj7 {
  margin-right: 20px;
}

.transactions-datetime_container-A2zj7:nth-child(2) {
  margin-right: 16px;
}

.transactions-datetime_input-nHivs {
  height: 40px;
  font-size: 15px;
}

.transactions-filterButton_base-zCMFd {
  padding: 0px 12px 0px 8px;
  margin-right: 4px;
}

.transactions-filterButton_base-zCMFd:hover {
  background-color: rgba(0, 0, 0, 0.05)
}

.transactions-filterButton_icon-JajN3 {
  --size: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 8px;
}

.transactions-filterButton_text-E8MSv {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.transactions-button_base-uDOmg {
  margin-left: auto;
}

.transactions-table_container-wVXof {
  width: initial;
  flex-grow: 1;
  padding: 0px 10px;
  margin: 4px 0px 0px 10px;
}

.transactions-tableHeader_cellContent-toY7f {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8)
}

.transactions-tableRow_cell-WWowQ:last-child {
  padding-right: 16px;
}

.transactions-phone-MKDj9 {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/transactions/transactions.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE;AACF;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf;AACF;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.filters {\n  display: flex;\n  align-items: center;\n  padding: 8px 12px 0px;\n}\n\n.datetime_container {\n  width: 220px;\n}\n\n.datetime_container {\n  margin-right: 20px;\n}\n\n.datetime_container:nth-child(2) {\n  margin-right: 16px;\n}\n\n.datetime_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.filterButton_base {\n  padding: 0px 12px 0px 8px;\n  margin-right: 4px;\n}\n\n.filterButton_base:hover {\n  background-color: rgba(0, 0, 0, 0.05)\n}\n\n.filterButton_icon {\n  --size: 20px;\n  color: rgba(0, 0, 0, 0.7);\n  margin-right: 8px;\n}\n\n.filterButton_text {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.button_base {\n  margin-left: auto;\n}\n\n.table_container {\n  width: initial;\n  flex-grow: 1;\n  padding: 0px 10px;\n  margin: 4px 0px 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8)\n}\n\n.tableRow_cell:last-child {\n  padding-right: 16px;\n}\n\n.phone {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `transactions-container-kM7HX`,
	"filters": `transactions-filters-n_qpV`,
	"datetime_container": `transactions-datetime_container-A2zj7`,
	"datetime_input": `transactions-datetime_input-nHivs`,
	"filterButton_base": `transactions-filterButton_base-zCMFd`,
	"filterButton_icon": `transactions-filterButton_icon-JajN3`,
	"filterButton_text": `transactions-filterButton_text-E8MSv`,
	"button_base": `transactions-button_base-uDOmg`,
	"table_container": `transactions-table_container-wVXof`,
	"tableHeader_cellContent": `transactions-tableHeader_cellContent-toY7f`,
	"tableRow_cell": `transactions-tableRow_cell-WWowQ`,
	"phone": `transactions-phone-MKDj9`
};
export default ___CSS_LOADER_EXPORT___;
